import React, { useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { JwtPayload } from "./jwt";
import { useLocalStorage } from "usehooks-ts";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const TokenRefresher = () => {
  const [token, setToken, removeToken] = useLocalStorage<string | null>('token', null, {
    deserializer: (v) => v || null,
    serializer: (v) => v || '',
  });

  useEffect(() => {
    if (!token) {
      console.info('No token found in localStorage');
      return;
    }

    const decodedToken = jwtDecode<JwtPayload>(token);
    console.info('Token found in localStorage', decodedToken);
    const expirationTime = decodedToken.exp! * 1000; // Convert to milliseconds
    const currentTime = Date.now();
    const timeUntilExpire = expirationTime - currentTime;
    const timeUntilRefresh = expirationTime - currentTime - (5 * 60 * 1000); // 5 minutes before expiry

    if (timeUntilExpire <= 2) { // 2 seconds left, not enough time to refresh
      console.warn('Token already expired');
      removeToken();
    } else if (timeUntilRefresh <= 0) {
      console.warn('Token is about to expire very soon');
      // Refresh the token immediately
      refreshAuthToken();
    } else {
      const timeoutId = setTimeout(() => {
        refreshAuthToken();
      }, timeUntilRefresh);

      // Cleanup the timeout if the component unmounts or the token changes
      return () => clearTimeout(timeoutId);
    }
  }, [token]); // if token changes, re-run the effect, setting a new timer

  const refreshAuthToken = async () => {
    try {
      if (!token) {
        throw new Error('No token found in localStorage');
      }

      const decodedToken = jwtDecode<JwtPayload>(token);
      const response = await axios.post(`${backendUrl}/auth-token`, {
        refresh_token: decodedToken.refreshToken!,
      });

      const newToken = response.data.token;
      setToken(newToken);
    } catch (error) {
      console.error('Failed to refresh auth token', error);
      removeToken();
    }
  };

  return null;
};

export default TokenRefresher;
