import { Box } from "@mui/material";

export function PrettyTagList(props: { tags: string[] }) {
  return (
    <Box sx={{
      fontStyle: 'italic',
      textWrap: 'balance',
    }}>
      {props.tags.join(', ')}

      {/*{props.tags.map((t, i) => (*/}
      {/*  <Box component="span" key={i}>*/}
      {/*    <Box component="span" sx={{*/}
      {/*      // display: 'inline-block', // avoid breaking inside*/}
      {/*    }}>*/}
      {/*      {t}{i < props.tags.length - 1 ? ',' : ''}*/}
      {/*    </Box>*/}
      {/*    {' '}*/}
      {/*  </Box>*/}
      {/*))}*/}
    </Box>
  );
}