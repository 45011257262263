import React, { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import { Alert, Box, TextField } from '@mui/material';
import { AdversaryCard } from '../../../adversaries/AdversaryCard';
import { Adversary } from '../../../adversaries/Adversary.type';
import { ModalInner } from '../../../common/ModalInner';
import { CompactNumberInput } from '../../../common/input/CompactNumberInput';
import { CardInstance, DeckCardUpdateProps } from '../DeckBuilder/Deck.types';
import { Header2 } from "../../../common/typography/Header2";
import { adversaryInstancePlaceholder } from "../../../adversaries/Adversary/AdversaryName";
import { Environment } from "../../../adversaries/Environment.type";
import { isAdversary } from "../../../adversaries/adversary.util";

interface CardCustomizationProps {
  open: boolean;
  onClose: () => void;
  onSave: (updated: DeckCardUpdateProps) => void;
  isNew: boolean;
  quantity: number;
  quantityNotes?: string;
  upscaling: number;
  instances?: CardInstance[];
  adversary?: Adversary | Environment;
}

function newInstance(): CardInstance {
  return {
    damageTaken: 0,
    stressTaken: 0,
    nameOverride: '',
    featuresUses: [],
  };
}

export function CardCustomizationDialog(props: CardCustomizationProps) {
  const {
    open, onClose, onSave, isNew, adversary, quantityNotes
  } = props;
  const [quantity, setQuantity] = useState(props.quantity);
  const [upscaling, setUpscaling] = useState(props.upscaling);
  const [instances, setInstances] = useState(
    props.instances ?? Array(props.quantity).fill('').map(() => newInstance()),
  );

  const onQuantityChange = (v: number) => {
    setQuantity(v);
    // If more than before then add instances
    if (v > instances.length) {
      const newInstances = [...instances];
      for (let i = instances.length; i < v; i++) {
        newInstances.push(newInstance());
      }
      setInstances(newInstances);
    }

    // If less than before then remove instances
    if (v < instances.length) {
      setInstances(instances.slice(0, v));
    }
  };

  const handleSave = () => {
    onSave({
      quantity,
      upscaling,
      instances,
    });
    onClose();
  };

  if (!adversary) {
    return null;
  }

  // const centerContentStyle = {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // };

  return (
    <Modal open={open} onClose={onClose} onClick={onClose}>
      <ModalInner>
        {/*<form onSubmit={(e) => {*/}
        {/*  handleSave();*/}
        {/*  e.preventDefault();*/}
        {/*}}>*/}
          <Box
            sx={{ display: 'flex', flexDirection: 'row-reverse' }}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking on the dialog
          >
            <Box sx={{
              mt: 6
            }}>
              <Card sx={{
                borderRadius: '0 6px 6px 0',
                boxShadow: '10px 0 8px -10px rgba(0,0,0,.6) inset',
                border: '3px solid #ccc',
                borderLeft: 'none',
                borderColor: 'warning.main',
                // marginTop: 10,
              }}>
                <Header2 sx={{ textAlign: 'center', pt: 2 }}>Customize Adversary</Header2>
                <DialogContent sx={{
                  maxHeight: 'calc(100vh - 200px)',
                }}>
                  <Box mb={1}>
                    {quantityNotes && (
                      <Alert severity="info" sx={{ mb: 1 }}>{quantityNotes}</Alert>
                    )}
                    {isAdversary(adversary) && (
                      <NumberInput
                        label="Quantity"
                        value={quantity}
                        onChange={onQuantityChange}
                        min={1}
                        max={20}
                      />
                    )}
                  </Box>
                  <Box>
                    <NumberInput
                      label="Upscaling"
                      value={upscaling}
                      onChange={setUpscaling}
                      min={-2}
                      max={5}
                    />
                  </Box>
                  <Box mt={2}>
                    <NameOverrideInput
                      adversaryName={adversary.name}
                      instances={instances}
                      onChange={(i, v) => {
                        const newInstances = [...instances];
                        newInstances[i] = {...newInstances[i], nameOverride: v};
                        setInstances(newInstances);
                      }}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button onClick={handleSave} color="primary" variant="contained" size="large">
                    {isNew ? 'Add' : 'Save'}
                  </Button>
                </DialogActions>
              </Card>
            </Box>
            <Box className="no-scrollbar" width={345} sx={{
              maxHeight: 'calc(100vh)',
              overflowY: 'auto',
              py: 2,
              fontSize: 14,
            }}>
              <Box maxWidth='100%' onClick={(e) => e.stopPropagation()}>
                <AdversaryCard
                  adversary={adversary}
                  onInstancesUpdate={(instances) => {
                    setInstances(instances);
                    setQuantity(instances.length);
                  }}
                  card={{
                    quantity,
                    upscaling,
                    instances,
                  }}
                />
              </Box>
            </Box>
          </Box>
        {/*</form>*/}
      </ModalInner>
    </Modal>
  );
}

function NameOverrideInput(props: {
  adversaryName: string,
  onChange: (i: number, v: string) => void,
  instances: CardInstance[],
}) {
  return (
    <Box>
      {props.instances.map((instance, i) => (
        <Box key={i} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Box flexGrow={1}>
            <TextField
              label={adversaryInstancePlaceholder(props.adversaryName, props.instances, i)}
              value={instance.nameOverride}
              onChange={(e) => props.onChange(i, e.target.value)}
            />
          </Box>
        </Box>
      ))}
    </Box>
  )
}

interface NumberInputProps {
  label: string;
  value: number;
  onChange: (v: number) => void;
  min: number;
  max: number;
}

function NumberInput(props: NumberInputProps) {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.1rem',
    }}>
      <Box flexGrow={1} textAlign="center">
        {props.label}:
        <Box minWidth='1.2em' textAlign="right" display="inline-block">
          {props.value}
        </Box>
      </Box>
      <CompactNumberInput
        value={props.value}
        onChange={(e, v) => props.onChange(v ?? 1)}
        min={props.min}
        max={props.max}
      />
    </Box>
  );
}
