import { useEncounters } from "../hooks/useEncounters";
import React, { useState } from "react";
import { isOpenSubject } from "../top-bar/menu-items/EncounterBuilderButton";
import {
  Alert,
  Box,
  Button,
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { LoginButton } from "../top-bar/gm-main-menu/AuthGuard";
import { useAuth } from "../hooks/useAuth";
import { TransparentAddButton } from "../../common/TransparentAddButton";
import { EncounterCatalogItem } from "./EncounterCatalogItem";
import { LightMode } from "../../core/muiThemeProviders";

interface Props {
  close: () => void;
}

export function EncountersCatalog(props: Props) {
  return (
    <Box sx={{
      m: '0 auto',
      p: 2,
      maxWidth: '100%',
      width: 800,
    }}>
      <Box mb={3}>
        <EncountersCatalogInner close={props.close} />
      </Box>
      <LoginArea />
    </Box>
  )
}

function EncountersCatalogInner(props: Props) {
  const {
    encounters,
    currentEncounter,
    setCurrentEncounterId,
    addEncounter,
    setSyncStatus,
    deleteEncounter
  } = useEncounters();
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedEncounterId, setSelectedEncounterId] = useState<string | null>(null);

  const handleEditOpen = (id: string) => {
    setCurrentEncounterId(id);
    isOpenSubject.next(true);
    props.close();
  };

  const handleDeleteOpen = (id: string) => {
    setSelectedEncounterId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleAddEncounter = () => {
    const id = addEncounter('New Encounter');
    setCurrentEncounterId(id);
    isOpenSubject.next(true);
    props.close();
  };

  const handleDeleteEncounter = () => {
    if (selectedEncounterId) {
      deleteEncounter(selectedEncounterId);
      setDeleteDialogOpen(false);
    }
  };


  return (
    <div>
      <TransparentAddButton
        onClick={handleAddEncounter}
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          py: 1,
          px: 2,
          borderRadius: 1,
          height: 62,
        }}
      >
        <Box>New Encounter</Box>
        <AddIcon sx={{ fontSize: '1.5em' }} />
      </TransparentAddButton>
      <LightMode>
        {encounters.map(encounter => (
          <EncounterCatalogItem
            key={encounter.id}
            encounter={encounter}
            onClick={() => setCurrentEncounterId(encounter.id)}
            onEdit={() => handleEditOpen(encounter.id)}
            onDelete={() => handleDeleteOpen(encounter.id)}
            onShouldStoreClick={() => setSyncStatus(encounter.id, !encounter.shouldStoreOnServer)}
            selected={currentEncounter.id === encounter.id}
          />
        ))}
      </LightMode>

      <Dialog open={deleteDialogOpen} onClose={handleDeleteClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this encounter?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button onClick={handleDeleteEncounter}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


function LoginArea() {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return null
  }

  return (
    <>
      <Alert severity="info" variant="outlined" action={
        <LoginButton />
      }>
        Log in to save your encounters in the cloud and access them from anywhere.
      </Alert>
    </>
  )
}
