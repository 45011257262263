import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

export function AdversaryDescription(props: PropsWithChildren) {
  return (
    <Box color="text.secondary" mb={1} sx={{
      'text-wrap': 'balance',
    }}>
      {props.children}
    </Box>
  );
}
