import * as Ably from "ably";
import shortUuid from "short-uuid";
import { BehaviorSubject } from "rxjs";

// const clientId = shortUuid.generate();

export const ablyStateSubject = new BehaviorSubject<Ably.ConnectionStateChange | null>(null);

// We save the last error in a separate subject so that we can display
// it in the UI, even after the state has changed to "connecting".
export const ablyLastErrorSubject = new BehaviorSubject<Ably.ConnectionStateChange | null>(null);

export const ablyClient = new Ably.Realtime({
  key: process.env.REACT_APP_ABLY_API_KEY,
  clientId: getClientId(),

  // https://ably.com/docs/connect/states?lang=javascript#connection-state-recover-options
  // Always recover – always recover the previous connection state if possible
  recover: (_, cb) => { cb(true); },

  // When the connection enters the ConnectionStates. SUSPENDED state, after this delay in milliseconds,
  // if the state is still | SUSPENDED,
  // the client library attempts to reconnect automatically. The default is 30 seconds.
  suspendedRetryTimeout: 5000,
  disconnectedRetryTimeout: 5000,
});

ablyClient.connection.on((stateChange) => {
  console.info('New connection state is ' + stateChange.current);
  ablyStateSubject.next(stateChange);
});

ablyClient.connection.on('failed', (error) => {
  console.error("Connection failed:", error);
  ablyLastErrorSubject.next(error);
});

ablyClient.connection.on('suspended', (error) => {
  console.error("Connection suspended:", error);
  ablyLastErrorSubject.next(error);
});

export function getClientId() {

  // return clientId;

  const clientId = localStorage.getItem("ablyClientId")
  if (!clientId) {
    const newUuid = shortUuid.generate();
    localStorage.setItem("ablyClientId", newUuid);

    return newUuid;
  }

  return clientId;
}
