import { useLocalStorage } from "usehooks-ts";
import { jwtDecode } from "jwt-decode";

export function useAuth() {
  // TokenRefresher will update the token when it is about to expire and remove it if it is expired
  // This will change the state of this hook as well.
  const [token] = useLocalStorage<string | null>('token', null, {
    deserializer: (v) => v,
  });

  const decodedToken = token ? jwtDecode(token) : null;

  return {
    token,
    decodedToken,
    isLoggedIn: decodedToken ? Date.now() < decodedToken.exp! * 1000 : false,
  };
}
