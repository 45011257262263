import { Box } from "@mui/material";
import { scaleDamage } from "../../dice/dice-scaling";

interface TextParserProps {
  text: string;
  upscaling: number;
  onDiceRoll?: (dice: string) => void;
}

export const TextParser: React.FC<TextParserProps> = (props) => {
  const { text, upscaling, onDiceRoll } = props;

  const parseText = (text: string) => {
    // Regex pattern to match dice rolls (e.g., 2d4, 2d12 +2, 1d6 -1)
    const actionRegex = /(spend fear|spend a fear|mark stress|mark a stress)/gi;
    const skillCheckRegex = /(Agility|Strength|Finesse|Instinct|Presence|Knowledge)/gi;
    // const skillCheckRegex = /(Restrained)/gi;
    const diceRegex = /(\b\d+d\d+(?:\s*[+-]\s*\d+)?\b)/g;

    // Combine patterns
    const combinedPattern = new RegExp(
      `${actionRegex.source}|${diceRegex.source}|${skillCheckRegex.source}`,
      'gi'
    );

    const parsedText = text.split(combinedPattern).map((part, index) => {
      if (!part) {
        return null;
      }

      if (part.match(actionRegex)) {
        return <CostHighlight key={index} content={part} />;
      } else if (part.match(diceRegex)) {
        return <DiceRoll key={index} content={scaleDamage(part, upscaling)} onClick={onDiceRoll}  />;
      } else if (part.match(skillCheckRegex)) {
        return <SkillCheckHighlight key={index} content={part} />;
      } else {
        return part;
      }
    });

    return <>{parsedText}</>;
  };

  return parseText(text);
};

interface DiceRollProps {
  content: string;
  onClick?: (dice: string) => void;
}

export const DiceRoll: React.FC<DiceRollProps> = (props) => {
  const { content, onClick } = props;

  return <Box
    onMouseDown={(e) => {
      e.stopPropagation() // Prevent the click triggering mui ripple
    }}
    onClick={(e) => {
      e.stopPropagation(); // Prevent the click from opening sidebar
      if (onClick) {
        onClick(content);
      }
    }}
    component="span"
    sx={{
      display: 'inline-block',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      minWidth: '1.75em',
      cursor: 'pointer',
      border: '1px solid #00000022',
      padding: '0.02em 0.3em',
      borderRadius: '0.3em',
      '&:hover': {
        background: '#00000011',
      }
    }}
  >{content}</Box>;
};


interface PhraseHighlightProps {
  content: string;
}

export const CostHighlight: React.FC<PhraseHighlightProps> = (props) => {
  const { content } = props;

  return (
    <Box
      component="span"
      sx={{
        fontWeight: '600',
        fontStyle: 'italic',
      }}
    >
      {content}
    </Box>
  );
};


export const SkillCheckHighlight: React.FC<PhraseHighlightProps> = (props) => {
  const { content } = props;

  return (
    <Box
      component="span"
      sx={{
        fontWeight: '600',
      }}
    >
      {content}
    </Box>
  );
};

