import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { grey, red } from "../core/muiTheme";

interface RoundButtonProps {
  onClick: () => void,
  size: string,
  color?: string,
}

export function EditButton(props: RoundButtonProps) {
  return (
    <IconButton
      onClick={props.onClick}
      size="small"
      sx={{
        fontSize: props.size,
        width: `1.5em`,
        height: `1.5em`,
        color: props.color || "white",
        backgroundColor: 'primary.light',
        '&:hover': { backgroundColor: 'info.dark' },
      }}
    >
      <EditIcon fontSize="inherit"/>
    </IconButton>
  );
}

export function DeleteButton(props: RoundButtonProps) {
  return (
    <IconButton
      onClick={props.onClick}
      size="small"
      color="error"
      sx={{
        fontSize: props.size,
        width: `1.5em`,
        height: `1.5em`,
        color: props.color || "white",
        backgroundColor: red[500],
        '&:hover': { backgroundColor: red[700] },
      }}
    >
      <ClearIcon fontSize="inherit"/>
    </IconButton>
  );
}

export function InstanceDeleteButton(props: RoundButtonProps) {
  return (
    <IconButton
      onClick={props.onClick}
      size="small"
      sx={{
        fontSize: props.size,
        width: `1.5em`,
        height: `1.5em`,
        color: grey[800],
        backgroundColor: props.color || "white",
        '&:hover': {
          backgroundColor: red[500],
          color: red[900],
        },
      }}
    >
      <ClearIcon fontSize="inherit"/>
    </IconButton>
  );
}