import React, { useState } from "react";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { HbAdversaryCreateDialog } from "./HbAdversaryCreateDialog";
import { TransparentAddButton } from "../../common/TransparentAddButton";
import { LightMode } from "../../core/muiThemeProviders";

export function HbAdversaryCreateButton() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}>
      <TransparentAddButton
        onClick={() => setIsOpen(true)}
        sx={{
          minHeight: '14em',
          flexDirection: 'column',
          padding: 1,
        }}
      >
        <AddIcon sx={{ fontSize: '5em' }} />
        <Box sx={{ fontSize: '1.4em' }} >New <br /> adversary</Box>
      </TransparentAddButton>
      <LightMode>
      <HbAdversaryCreateDialog
        open={isOpen}
        handleClose={() => setIsOpen(false)}
      />
      </LightMode>
    </Box>
  );
}
