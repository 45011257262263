import React from 'react';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import Button from '@mui/material/Button';
import { useEncounters } from "../../hooks/useEncounters";
import { useAuth } from "../../hooks/useAuth";

export function ShouldStoreOnServerButton() {
  const { currentEncounter, setSyncStatus } = useEncounters();

  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div>
      {currentEncounter.shouldStoreOnServer ? (
        <Button onClick={() => {setSyncStatus(currentEncounter.id, false)}} startIcon={<CloudDoneIcon/>}>
          Sync with account
        </Button>
      ) : (
        <Button onClick={() => {setSyncStatus(currentEncounter.id, true)}} startIcon={<CloudOffIcon/>}>
          Sync with account
        </Button>
      )}
    </div>
  );
}


