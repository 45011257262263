// Shows a modal of with a link that te GM can copy to share with the players

import React, { useState } from "react";
import Button from "@mui/material/Button";
import UserIcon from "@mui/icons-material/Group";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography } from "@mui/material";
import LinkCopy from "../../../common/input/LinkCopy";
import { useGmGameId } from "../../hooks/useGmGameId";

export function PlayersButton() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} startIcon={<UserIcon/>}>
        Players
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" PaperProps={{
        sx: {
          borderRadius: 2,
          borderWidth: 2,
          borderColor: 'warning.main',
          borderStyle: 'solid',
        },
      }}>
        <DialogContent>
          <PlayerInvite />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export function PlayerInvite() {
  return (
    <>
      <PlayerInviteMessage/>
      <Box textAlign="center">
        <Typography mb={2} mt={2}>
          You need to enable <strong>"Show player dice rolls"</strong> <br/>
          in the settings to see the rolls. <br/>
          Or you can open the link yourself in a different window or device.
        </Typography>
      </Box>
    </>
  );
}

export function PlayerInviteMessage() {
  const gameId = useGmGameId();

  return (
    <>
      <Box textAlign="center">
        <Typography mb={2}>
          Share this link with your players! <br/>
          They will be able to roll die and see each other's rolls.
        </Typography>
      </Box>
      <LinkCopy link={window.location.href + 'player/' + gameId}/>
    </>
  );
}
