import { useAuth } from "./useAuth";
import {
  useDeleteEncounterMutation,
  useListEncountersQuery,
  usePutEncounterMutation,
} from "../../generated/graphql";
import { EncounterState } from "../types";
import { useSnackbar } from "notistack";

export function useServerEncounters() {
  const { isLoggedIn } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const {data} = useListEncountersQuery({
    skip: !isLoggedIn,
    onError: (error) => {
      enqueueSnackbar('Failed to fetch your encounters from server: ' + error.message, { variant: 'error' });
    }
  })

  const [put] = usePutEncounterMutation({
    onError: (error) => {
      enqueueSnackbar('Failed to save encounter: ' + error.message, { variant: 'error' });
    }
  });

  const [deleteEncounter] = useDeleteEncounterMutation({
    onError: (error) => {
      enqueueSnackbar('Failed to delete encounter: ' + error.message, { variant: 'error' });
    }
  });

  const storedEncounters: EncounterState[] = data?.listMyEncounters.map(enc => {
    return {
      shouldStoreOnServer: true,
      id: enc.id,
      name: enc.name,
      updatedAt: enc.updatedAt,
      deck: enc.deck,
    };
  }) || [];

  return {
    storedEncounters,
    storeEncounter: async (input: EncounterState) => {
      if (!isLoggedIn) {
        enqueueSnackbar('Unable to save encounter: Not logged in', { variant: 'error' });
        return;
      }

      await put({
        variables: {
          input: {
            id: input.id,
            name: input.name,
            updatedAt: input.updatedAt,

            // In general, we just need to remove __typename from the objects
            deck: input.deck.map(card => {
              return {
                id: card.id,
                name: card.name,
                adversaryId: card.adversaryId, // Used by homebrew only
                quantity: card.quantity,
                upscaling: card.upscaling,
                rolls: card.rolls?.map(roll => {
                  return {
                    diceRolls: roll.diceRolls.map(diceRoll => ({
                      sides: diceRoll.sides,
                      type: diceRoll.type,
                      value: diceRoll.value,
                    })),
                    isCrit: roll.isCrit,
                    modifier: roll.modifier,
                    playerName: roll.playerName,
                    total: roll.total,
                    withHope: roll.withHope,
                  }
                }),
                instances: card.instances.map(instance => {
                  return {
                    damageTaken: instance.damageTaken,
                    stressTaken: instance.stressTaken,
                    nameOverride: instance.nameOverride,
                    featuresUses: instance.featuresUses,
                  }
                }),
              }
            }),
          }
        }
      });
    },
    deleteEncounter: async (id: string) => {
      if (!isLoggedIn) {
        enqueueSnackbar('Unable to delete encounter: Not logged in', { variant: 'error' });
        return;
      }

      await deleteEncounter({
        variables: {
          id
        }
      });
    }
  };
}
