import { Box } from "@mui/material";
import { blue, grey, red, yellow } from "../core/muiTheme";
import { InstanceDeleteButton } from "../common/RoundButton";
import { BoxBar } from "./Adversary/AdversaryHealth";
import { CardInstance } from "../game-master/encounter/DeckBuilder/Deck.types";
import { Adversary } from "./Adversary.type";
import { adversaryInstanceName } from "./Adversary/AdversaryName";
import { AdversaryFeature } from "../generated/graphql";

interface InstanceInfoProps {
  instance: CardInstance;
  onUpdate: (newInstance: CardInstance) => void;
  onRemove?: () => void;
  adversary: Adversary;
  showName: boolean;
  index: number;
}

export function InstanceInfo(props: InstanceInfoProps) {
  const {
    instance, adversary, onUpdate, showName,
  } = props;

  // extract uses of features
  const featuresWithUses = adversary.features?.filter(f => f.uses).map(f => f) || [];

  return (
    <>
      {showName && (
        <Box sx={{
          backgroundColor: grey[800],
          fontWeight: 'bold',
          color: 'white',
          paddingX: 2,
          paddingY: 0.5,
          marginX: -2,
          marginY: 2,
          display: 'flex',
          alignItems: 'center',
        }}>
          <Box sx={{ flexGrow: 1 }}>
            {adversaryInstanceName(adversary.name, instance, props.index)}
          </Box>
          {props.onRemove && (
            <InstanceDeleteButton onClick={props.onRemove} size="0.9em"/>
          )}
        </Box>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box width="6em" fontWeight="900" color={grey[700]} fontSize="0.8em">
          HP
        </Box>
        <BoxBar
          max={adversary.hitPoints}
          value={instance.damageTaken}
          color={red[600]}
          onUpdate={(value) => {
            onUpdate({ ...instance, damageTaken: value });
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }} mt={1}>
        <Box width="6em" fontWeight="900" color={grey[700]} fontSize="0.8em">
          STRESS
        </Box>
        <BoxBar
          max={adversary.stress}
          value={instance.stressTaken}
          color={yellow[600]}
          onUpdate={(value) => {
            onUpdate({ ...instance, stressTaken: value });
          }}
        />
      </Box>
      {adversary.hordeUnitsPerHp && (
        <Box sx={{ display: 'flex', alignItems: 'center' }} mt={1.5}>
          <Box fontWeight="900" color={grey[700]} fontSize="0.8em" pr={1}>
            HORDE SIZE:
          </Box>
          <Box fontWeight="bold">
            {(adversary.hitPoints - instance.damageTaken) * adversary.hordeUnitsPerHp}
          </Box>
        </Box>
      )}
      {featuresWithUses.map((feature, i) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }} mt={1} key={i}>
          <Box width='20em' fontWeight="900" color={grey[700]} fontSize="0.8em">
            {feature.name}
          </Box>
          <BoxBar
            max={feature.uses!}
            value={getUsesOfInstanceFeature(instance, feature)}
            color={blue[600]}
            onUpdate={(value) => {
              onUpdate(updateUsesOfInstanceFeature(instance, feature, value));
            }}
          />
        </Box>
      ))}
    </>
  );
}

function getUsesOfInstanceFeature(instance: CardInstance, feature: AdversaryFeature) {
  const uses = instance.featuresUses || [];

  return uses.find(u => u.name === feature.name)?.uses || 0;
}

/**
 * Update the feature uses count on a card instance
 */
function updateUsesOfInstanceFeature(instance: CardInstance, feature: AdversaryFeature, newValue: number) {
  const uses = instance.featuresUses || [];
  const existing = uses.find(u => u.name === feature.name);

  if (existing) {
    return {
      ...instance,
      featuresUses: uses.map(u => {
        if (u.name === feature.name) {
          return { name: u.name, uses: newValue };
        }
        return u;
      }),
    };
  }

  return {
    ...instance,
    featuresUses: [...uses, { name: feature.name, uses: newValue }],
  };
}
