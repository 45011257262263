import { DarkMode, LightMode } from "../core/muiThemeProviders";
import { Alert, Box, Button } from "@mui/material";
import { purple } from "../core/muiTheme";
import React from "react";

export function ThemeTest() {
  return (
    <div>
      <LightMode>
        <Box p={10}>
          <Components />
        </Box>

      </LightMode>

      <DarkMode>
        <Box sx={{
          background: purple[700],
          p: 10,
        }}>
          <Components />
        </Box>
      </DarkMode>
    </div>
  );
}

export function Components() {
  return (
    <Box sx={{
      '& > *': {
        m: 1,
      }
    }}>
      <Alert severity="error">Error</Alert>
      <Alert severity="warning">Warning</Alert>
      <Alert severity="info">Info</Alert>
      <Alert severity="success">Success</Alert>
      <Alert severity="error" variant="outlined">Error</Alert>
      <Alert severity="warning" variant="outlined">Warning</Alert>
      <Alert severity="info" variant="outlined">Info</Alert>
      <Alert severity="success" variant="outlined">Success</Alert>
      <Alert severity="error" variant="filled">Error</Alert>
      <Alert severity="warning" variant="filled">Warning</Alert>
      <Alert severity="info" variant="filled">Info</Alert>
      <Alert severity="success" variant="filled">Success</Alert>
      <Button variant="contained" color="primary">Primary</Button>
      <Button variant="contained" color="secondary">Secondary</Button>
      <Button variant="contained" color="error">Error</Button>
      <Button variant="outlined" color="primary">Primary</Button>
      <Button variant="outlined" color="secondary">Secondary</Button>
      <Button variant="outlined" color="error">Error</Button>
    </Box>
  )
}