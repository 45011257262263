import {
  FeatureUseCount,
} from "../generated/graphql";

interface OldEncounterState {
  currentEncounterId: string | null;
  encounters: {
    id: string; // This is a timestamp like '1736785420893'
    updatedAt?: number;
    deck: {
      instances: {
        // Old structure was a map/dictionary
        featuresUses: { [name: string]: number };
      }[];
    }[];
  }[];
}

/**
 * Migrate encounters instances featuresUses to the new structure
 */
export function migrateEncounters(): void {
  const rawEncounters = localStorage.getItem('encounters');
  if (!rawEncounters) {
    // If there's no encounters, nothing to migrate
    return;
  }

  let state: OldEncounterState;
  try {
    state = JSON.parse(rawEncounters) as OldEncounterState;
  } catch (err) {
    // If encounters are somehow invalid JSON, we could handle that here.
    console.error('Failed to parse encounters from localStorage', err);
    return;
  }

  const updatedEncounters = state.encounters.map((encounter) => {
    // Because the default encounters have the same id "1", we need to make them unique.
    // So that if you have used the app in different browsers, they don't overwrite
    // each other when starting to sync with an account
    // We set ut to a random number between that is lower than the timestamp of any other possible encounter so that
    // it will always be the first one.
    if (encounter.id === '1') {
      encounter.id = Math.floor(Math.random() * 1000000000).toString();
    }

    return {
      ...encounter,
      updatedAt: encounter.updatedAt || +encounter.id,
      deck: encounter.deck.map((card) => {
        return {
          ...card,
          instances: card.instances.map((instance) => {
            if (!instance.featuresUses) {
              return { ...instance, featuresUses: [] };
            }

            return {
              ...instance,
              featuresUses: Object.entries(instance.featuresUses).map(([name, uses]) => {
                return { name, uses } as FeatureUseCount;
              }),
            };
          }),
        };
      }),
    }
  });

  // Separating into two different items in localStorage
  if (state.currentEncounterId && state.currentEncounterId !== '1') {
    localStorage.setItem('currentEncounterId', JSON.stringify(state.currentEncounterId));
  }
  localStorage.setItem('encounterList', JSON.stringify(updatedEncounters));
}