import React from "react";
import { Box } from "@mui/material";
import TokenRefresher from "../game-master/auth/TokenRefresher";
import { HbAdversaryForm } from "../game-master/homebrew-adversaries/HbAdversaryForm";
import { DevAdversaryCreate } from "./DevAdversaryCreate";
import { DevAdversaryList } from "./DevAdversaryList";
import { defaultAdversaryFormData } from "../game-master/homebrew-adversaries/config/defaultAdversaryFormData";
import { LoginButton } from "../game-master/top-bar/gm-main-menu/AuthGuard";
import { ThemeTest } from "./ThemeTest";

export function DevPage() {


  return (
    <Box m={1} sx={{
      overflowY: 'auto',
    }}>
      <DevAdversaryCreate />
      <LoginButton />

      <Box p={2}>
        <HbAdversaryForm
          onDelete={() => {
            console.log('onDelete');
          }}
          loading={false}
          onClose={() => {
            console.log('onClose');
          }}
          onSubmit={(adversary) => {
            console.log(adversary);
          }}
          defaultData={defaultAdversaryFormData}
        />
      </Box>
      <TokenRefresher />
      <DevAdversaryList />

      <ThemeTest />

    </Box>
  )

}
