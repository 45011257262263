import React from "react";
import Button from "@mui/material/Button";

export function LoginButton() {
  const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID!}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_DISCORD_REDIRECT_URI!)}&response_type=code&scope=identify`;

  return (
    <Button
      href={discordAuthUrl}
      variant="contained"
      // sx={{ color: 'black', borderColor: 'black', background: 'white' }}
      startIcon={<img src="/images/discord.svg" alt="Discord logo" style={{ width: '1em' }}/>}
    >
      Login
    </Button>
  );
}
