import { Box } from '@mui/material';
import { Adversary } from '../Adversary.type';
import { scaleDamage } from '../../dice/dice-scaling';
import { DiceRoll } from "./AdversaryFeatureTextParser";
import { AdversaryDifficulty } from "./AdversaryDifficulty";

interface Props {
  adversary: Adversary;
  upscaling?: number;
  onDiceRoll?: (result: string) => void;
}

export function AdversaryAttack(props: Props) {
  const { adversary } = props;
  const { weapon, attackModifier, difficulty } = adversary;
  const upscaling = props.upscaling ?? 0;
  const [damage, type] = weapon.damage.split(' ');
  const damageScaled = scaleDamage(damage, upscaling);

  let scaledAttackModifier: string;

  // if scaledAttackModifier contains a dice then don't scale it
  if (attackModifier && attackModifier.includes('d')) {
    scaledAttackModifier = attackModifier;
  } else {
    scaledAttackModifier = scaleAttackModifier(+attackModifier, upscaling).toString();
  }

  if (!weapon) {
    return null;
  }

  const damageButton = props.onDiceRoll ? (
    <DiceRoll content={damageScaled} onClick={props.onDiceRoll} />
  ) : (
    <>{damageScaled}</>
  );


  const modifierButton = props.onDiceRoll ? (
    <DiceRoll content={scaledAttackModifier} onClick={() => {
      props.onDiceRoll!('1d20 '+scaledAttackModifier);
    }} />
  ) : (
    <>{scaledAttackModifier}</>
  );

  return (
    <Box sx={{
      mb: 1,
      textWrap: 'pretty',
    }}>
      <strong>{weapon.name}:</strong> {weapon.range} - {damageButton} {type} <br/>
      Attack modifier: {modifierButton} <br/>
      <AdversaryDifficulty baseTeir={adversary.tier || 1} difficulty={difficulty} upscaling={upscaling} /> <br/>
    </Box>
  );
}

function scaleAttackModifier(attackModifier: number, upscaling: number) {
  const newValue = attackModifier + upscaling;

  return `${newValue > 0 ? '+' : ''}${newValue}`;
}

