import { DeckCard, DeckCardUpdateProps } from './Deck.types';
import { useEncounters } from "../../hooks/useEncounters";
import { DiceRollResultInput } from "../../../generated/graphql";

export function useDeck() {
  const {
    currentEncounter,
    updateCurrentDeck: setDeck,
  } = useEncounters()
  const deck = currentEncounter?.deck || [];


  const addCardToDeck = (name: string, adversaryId: string | undefined, values: DeckCardUpdateProps) => {
    // The deck is not sorted by id. So we need to find the highest id
    const highestId = deck.reduce((acc, card) => (card.id > acc ? card.id : acc), 0);

    // Create a new card with a unique ID
    const newCard: DeckCard = {
      id: highestId + 1,
      adversaryId: adversaryId,
      name,
      ...values,
    };
    setDeck([...deck, newCard]);
  };

  const updateCardInDeck = (id: number, updated: Partial<DeckCard>) => {
    const updatedDeck = deck.map((card) => {
      if (card.id === id) {
        return { ...card, ...updated };
      }
      return card;
    });
    setDeck(updatedDeck);
  };

  const removeCardFromDeck = (id: number) => {
    const updatedDeck = deck.filter((card) => card.id !== id);
    setDeck(updatedDeck);
  };

  const reorderDeck = (startIndex: number, endIndex: number) => {
    const result = Array.from(deck);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setDeck(result);
  };

  const addDiceResultToCard = (cardId: number, diceRoll: DiceRollResultInput) => {
    const updatedDeck = deck.map((card) => {
      if (card.id === cardId) {
        return {
          ...card,
          rolls: [...(card.rolls || []), diceRoll],
        };
      }
      return card;
    });
    setDeck(updatedDeck);
  }

  return {
    deck,
    addCardToDeck,
    updateCardInDeck,
    removeCardFromDeck,
    reorderDeck,
    addDiceResultToCard,
  };
}
