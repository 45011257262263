import { Box } from '@mui/material';
import { Adversary } from '../Adversary.type';
import { PrettyTagList } from "./PrettyTagList";

export function MotivesAndTactics(props: { adversary: Adversary }) {
  const { motivesAndTactics } = props.adversary;

  if (!motivesAndTactics || motivesAndTactics.length === 0) {
    return null;
  }

  return (
      <Box>
        <strong>Motives & Tactics:</strong>
        <PrettyTagList tags={motivesAndTactics} />
      </Box>
  );
}
