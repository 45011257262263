import { migrateEncounters } from "./2-encounters";

export function runMigrations() {
  const storedVersionNumber = localStorage.getItem('appVersionNumber') || '0';
  const appVersionNumber = parseInt(storedVersionNumber, 10);

  // If we haven't set the version number yet or if it's less than 2
  if (appVersionNumber < 2) {
    // Migrate encounters to the new structure
    migrateEncounters();

    // Finally, set the version number so this migration won’t run again
    localStorage.setItem('appVersionNumber', '2');
  }
}