import { createTheme } from '@mui/material';

// export const red = '#ca0001';
// export const blue = '#2978be';
// export const purple = '#1e184d'

export const baseDarkBlue = '#0a0f21';
export const basePurple = '#1e184d';
export const basePurpleLight = '#38227b';
export const baseGold = '#f3c267';
export const baseGoldLight = '#fef790';
export const baseGoldDark = '#e38c3e';
export const baseTeal = '#81ccc3';
export const darkTeal = '#3882a4';
export const baseBlue = '#2878be';
export const baseOffWhite = '#F2F3F4';
export const baseGrey = '#eee';

export const fcgYellow = "#fce051"
export const fcgYellow2 = "#feb647"
export const fcgOrange = "#e8802b"
export const fcgRed = "#d43252"
export const fcgRed2 = "#981950"
export const fcgPurple = "#6d3a97"
export const fcgPurple2 = "#33134f"

export const lightGrey = '#787a7c'; // From pdf manual

export const baseGreen = '#2e7d32';

export const tealGradient = `linear-gradient(0deg, ${darkTeal} 0%, ${baseTeal} 75%, ${baseTeal} 100%)`;
// linear-gradient(180deg, rgba(3, 133, 202, 0.00) 14.58%, #0385CA 79.62%), var(--DH-Ligh-Green, #83CDC2)

export const blue = {
  900: '#013D72',
  800: '#074C88',
  700: '#105B9D',
  600: '#1B6AAF',
  500: '#2878be',
  400: '#3D89CC',
  300: '#549AD8',
  200: '#6DABE2',
  100: '#87BCEB',
  50: '#A3CDF2',
};

export const darkPurpleGradient = `linear-gradient(0deg, ${baseDarkBlue} 0%, ${blue[900]} 100%)`;


// https://hihayk.github.io/scale/#4/6/50/85/0/0/50/50/9DA8B7/157/168/183/white
export const grey = {
  900: '#4B545F',
  800: '#5F6975',
  700: '#737E8C',
  600: '#8893A2',
  500: '#9DA8B7',
  400: '#AAB4C2',
  300: '#B7C0CD',
  200: '#C5CDD8',
  100: '#D3D9E2',
  50: '#E1E5EC',
};

// https://hihayk.github.io/scale/#4/6/50/65/0/0/50/50/1e184d/30/24/77/white
export const purple = {
  900: '#0A052D',
  800: '#0E0936',
  700: '#130D3F',
  600: '#181246',
  500: '#1e184d',
  400: '#352F62',
  300: '#4C4777',
  200: '#645F8B',
  100: '#7C779F',
  50: '#9490B3',
};

// https://hihayk.github.io/scale/#4/6/50/65/0/0/50/50/d43252/212/50/82/white
export const red = {
  900: '#7E051D',
  800: '#980C28',
  700: '#AE1634',
  600: '#C22342',
  500: '#d43252',
  400: '#DF4261',
  300: '#E85471',
  200: '#F06782',
  100: '#F67C94',
  50: '#FB92A6',
};


// https://hihayk.github.io/scale/#4/6/50/65/0/0/50/50/feb647/254/182/71/white
export const yellow = {
  900: '#80600D',
  800: '#9F7617',
  700: '#BF8C24',
  600: '#DFA134',
  500: '#feb647',
  400: '#FFBF54',
  300: '#FFC863',
  200: '#FFD173',
  100: '#FFD985',
  50: '#FFE199',
};

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: purple[500],
    },
    warning: {
      main: yellow[500],
    },
    error: {
      main: red[600],
    },
  },
});


export const darkTheme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
  },
  palette: {
    mode: 'dark',
    primary: {
      main: blue[100],
    },
    secondary: {
      main: purple[200],
    },
    warning: {
      main: yellow[300],
    },
    error: {
      main: red[300],
    },
  },
});



