import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";

const discordLink = "https://discord.gg/uJtd9ahU92";


export function InfoText() {
  return (
    <Box textAlign="center">
      <Typography mb={3}>
        <DiscordButton2/>
      </Typography>
      <CopyrightText/>

    </Box>
  );
}

export function DiscordButton() {
  return (
    <Button
      href={discordLink}
      variant="outlined"
      target="_blank"
      sx={{ color: 'black', borderColor: 'black' }}
      startIcon={<img src="/images/discord.svg" alt="Discord logo" style={{ width: '1em' }}/>}
    >
      Join us on Discord to help improve this tool!
    </Button>
  );
}


export function DiscordButton2() {
  return (
    <Button
      href={discordLink}
      variant="contained"
      color="warning"
      target="_blank"
      sx={{ color: 'black', backgroundColor: 'white' }}
      startIcon={<img src="/images/discord.svg" alt="Discord logo" style={{ width: '1em' }}/>}
    >
      Join us on Discord to help improve this tool!
    </Button>
  );
}

export function CopyrightText() {
  return (
    <Box sx={{
      'text-wrap': 'balance',
      '& a': {
        color: 'inherit',
        textDecoration: 'underline',
      },
    }}>
      <Typography variant="h6" gutterBottom>
        Copyright Notice
      </Typography>
      <Typography variant="body1" gutterBottom>
        This website, was created using the Darrington Press Community Gaming License.{' '}
        <em>Daggerheart</em> is owned and copyrighted by Darrington Press, LLC. All rights reserved.
      </Typography>

      <Typography variant="body1" gutterBottom>
        All adversaries and their texts are copyrighted by Darrington Press, LLC. from the current beta test material for Daggerheart 1.5.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Images displayed on this website are AI-generated and are not official artwork from Darrington Press or its related companies.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Attribution
      </Typography>
      <Typography variant="body1" gutterBottom>
        This website is based on Public Game Content created and owned by Darrington Press: <em>Daggerheart</em>, Darrington Press, LLC, 2025, available at{' '}
        <a href="https://darringtonpress.com/" target="_blank" rel="noopener noreferrer">
          https://darringtonpress.com/
        </a>.
      </Typography>

      <Typography variant="h6" gutterBottom>
        License
      </Typography>
      <Typography variant="body1" gutterBottom>
        The Public Game Content identified above is licensed via the Darrington Press Community Gaming License, available at{' '}
        <a href="https://darringtonpress.com/license/" target="_blank" rel="noopener noreferrer">
          https://darringtonpress.com/license/
        </a>.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Modifications
      </Typography>
      <Typography variant="body1" gutterBottom>
        The stat blocks included on this website may have minor modifications to correct obvious errors.
      </Typography>

      {/*<Box sx={{ mt: 2, textAlign: 'center' }}>*/}
      {/*  <img*/}
      {/*    src="/images/darrington_CC_logo_white.png"*/}
      {/*    alt="Darrington Press Community Content Logo"*/}
      {/*    style={{ maxWidth: '80%', height: 'auto' }}*/}
      {/*  />*/}
      {/*</Box>*/}
    </Box>
  );
}
