import { Box } from '@mui/material';
import { Environment } from '../Environment.type';
import { PrettyTagList } from "./PrettyTagList";

export function AdversaryEnvironmentPotentialAdversaries(props: { adversary: Environment }) {
  const { potentialAdversaries } = props.adversary;

  if (!potentialAdversaries || potentialAdversaries.length === 0) {
    return null;
  }

  return (
    <Box sx={{
      mt: '0.5em',
    }}>
      <strong>Potential Adversaries:</strong>
      <PrettyTagList tags={potentialAdversaries} />
    </Box>
  );
}
