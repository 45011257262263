import { Box } from '@mui/material';
import React from 'react';
import { EncounterBuilderButton } from './menu-items/EncounterBuilderButton';
import { purple } from "../../core/muiTheme";
import { GmMainMenuDialogButton } from "./gm-main-menu/GmMainMenuDialogButton";
import { ShouldStoreOnServerButton } from "./menu-items/ShouldStoreOnServerButton";

// EncounterBuilderButton

export function TopBar() {
  return (
    <Box
      sx={{
        background: `${purple[500]}CC`,
        px: 1,
        color: 'white',
        '& button': {
          color: 'white',
          borderRadius: 0,
          fontWeight: 'bold',
          mx: 0.5,
        },
      }}
    >
      <Box display="flex">
        <GmMainMenuDialogButton />
        {/*<EncounterManager />*/}
        <EncounterBuilderButton />
        <ShouldStoreOnServerButton />
        {/*<PlayersButton />*/}
        {/*<InfoButton />*/}
        {/*<SettingsButton />*/}
      </Box>
    </Box>
  );
}
