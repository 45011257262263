import { useLocalStorage } from 'usehooks-ts';
import { DeckCard } from "../encounter/DeckBuilder/Deck.types";
import { EncounterState } from "../types";
import { useServerEncounters } from "./useServerEncounters";
import { currentTimestamp, currentTimestampNumeric } from "./encounter.util";

const defaultEncounter: EncounterState = {
  id: currentTimestamp(),
  name: 'My Encounter',
  updatedAt: currentTimestampNumeric(),
  deck: [],
};

export function useEncounters() {
  const [encountersState, setEncountersState] = useLocalStorage<EncounterState[]>('encounterList', []);
  const [currentEncounterId, setCurrentEncounterId] = useLocalStorage<string | null>('currentEncounterId', null);
  const {storedEncounters, storeEncounter, deleteEncounter: deleteStoredEncounter} = useServerEncounters();
  const allEncounters = mergeEncounters(storedEncounters, encountersState);

  // If there is no encounter then we create a temporary one and set it as the current one
  if (allEncounters.length === 0) {
    allEncounters.push(defaultEncounter);
  }

  let currentEncounter = allEncounters.find(encounter => encounter.id === currentEncounterId);

  // If the current encounter is not set, or it was deleted, we set the first one as the current one
  if (!currentEncounter) {
    currentEncounter = allEncounters[0];
  }

  const addEncounter = (name: string) => {
    const id = Date.now().toString();
    const newEncounter: EncounterState = {
      id: id,
      name,
      updatedAt: currentTimestampNumeric(),
      deck: [],
    };
    setEncountersState(prev => ([...prev, newEncounter]));

    return id;
  };

  const renameEncounter = (id: string, newName: string) => {
    setEncountersState(() => {
      // Note that this will also store server encounters locally, which is good because it acts as a cache
      return allEncounters.map((encounter) => {
        return encounter.id === id ? { ...encounter, name: newName, updatedAt: currentTimestampNumeric() } : encounter
      })
    });
  };

  const deleteEncounter = (id: string) => {
    setEncountersState(prev => {
      const updatedEncounters = prev.filter(encounter => encounter.id !== id);

      if (updatedEncounters.length === 0) {
        setCurrentEncounterId(defaultEncounter.id);
        return [defaultEncounter];
      }

      return updatedEncounters;
    });

    const updatedEncounter = allEncounters.find(encounter => encounter.id === id);

    if (updatedEncounter?.shouldStoreOnServer) {
      deleteStoredEncounter(id).then();
    }
  };

  const updateCurrentDeck = (deck: DeckCard[]) => {
    const updatedEncounter = {
      ...currentEncounter,
      deck,
      updatedAt: currentTimestampNumeric(),
    }

    setEncountersState(() => {
      // Note that this will also store server encounters locally, which is good because it acts as a cache
      return allEncounters.map((encounter) => encounter.id === currentEncounter.id ? updatedEncounter : encounter);
    });

    if (updatedEncounter.shouldStoreOnServer) {
      storeEncounter(updatedEncounter).then();
    }
  }

  const setSyncStatus = (id: string, shouldStoreOnServer: boolean) => {
    const targetEncounter = allEncounters.find(encounter => encounter.id === id);

    if (!targetEncounter) {
      return;
    }

    const updatedEncounter = {
      ...targetEncounter,
      shouldStoreOnServer: shouldStoreOnServer,
      updatedAt: currentTimestampNumeric(),
    }

    setEncountersState(() => {
      // Note that this will also store server encounters locally, which is good because it acts as a cache
      return allEncounters.map((encounter) => encounter.id === updatedEncounter.id ? updatedEncounter : encounter);
    });

    if (updatedEncounter.shouldStoreOnServer) {
      storeEncounter(updatedEncounter).then();
    } else {
      deleteStoredEncounter(updatedEncounter.id).then();
    }
  }

  return {
    encounters: allEncounters,
    currentEncounter,
    updateCurrentDeck,
    setCurrentEncounterId,
    addEncounter,
    renameEncounter,
    deleteEncounter,
    setSyncStatus,
  };
}

/**
 * Merges the stored encounters with the server encounters.
 * If an encounter is present in both, it should compare updatedAt and only keep the last updated.
 */
function mergeEncounters(storedEncounters: EncounterState[], encounters: EncounterState[]) {
  const mergedEncounters = [...storedEncounters];

  encounters.forEach(encounter => {
    const index = storedEncounters.findIndex(e => e.id === encounter.id);

    // if not found, add it
    if (index === -1) {
      mergedEncounters.push(encounter);
    } else {
      const storedEncounter = storedEncounters[index];

      if (storedEncounter.updatedAt < encounter.updatedAt) {
        mergedEncounters[index] = encounter;
      }
    }
  });

  // finally, sort by id (created timestamp)
  return mergedEncounters.sort((a, b) => parseInt(b.id) - parseInt(a.id));
}

