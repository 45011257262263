import { useListMyAdversariesQuery } from "../../generated/graphql";
import { Alert, Box } from "@mui/material";
import ErrorMessage from "../../common/ErrorMessage";
import React from "react";
import { DeckCatalogGrid } from "../encounter/DeckBuilder/DeckCatalog/DeckCatalogGrid";
import { HbAdversaryCreateButton } from "./HbAdversaryCreateButton";
import { HbAdversaryGridItem } from "./HbAdversaryGridItem";
import { useAuth } from "../hooks/useAuth";
import { LoginButton } from "../top-bar/gm-main-menu/AuthGuard";
import { LightMode } from "../../core/muiThemeProviders";

export function HomebrewCatalog() {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <HomebrewCatalogLoggedIn />;
  }

  return (
    <Box
      sx={{
        margin: '0 auto',
        maxWidth: '100%',
        width: 600,
        padding: 2,
        textAlign: 'center',
      }}
    >
      <Alert severity="info" variant="outlined" action={<LoginButton />}>
        Log in to create and manage your homebrew adversaries.
      </Alert>
    </Box>
  );
}

function HomebrewCatalogLoggedIn() {
  const { data, error } = useListMyAdversariesQuery();

  return (
    <Box pt={1}>
      <ErrorMessage error={error} />
      <DeckCatalogGrid>
        <HbAdversaryCreateButton />
        <LightMode>
          {data?.listMyAdversaries?.map((adversary) => (
            <Box key={adversary.name + adversary.id}>
              <HbAdversaryGridItem
                adversary={adversary}
              />
            </Box>
          ))}
        </LightMode>
      </DeckCatalogGrid>
    </Box>
  );
}



