import { ThemeProvider } from "@mui/material";
import { darkTheme, theme } from "./muiTheme";
import { PropsWithChildren } from "react";


export function DarkMode({ children }: PropsWithChildren) {
  return (
    <ThemeProvider theme={darkTheme}>
      {children}
    </ThemeProvider>
  );
}

export function LightMode({ children }: PropsWithChildren) {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}
