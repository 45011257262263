import { EncounterState } from "../types";
import { Box, ButtonBase, Paper, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { yellow } from "../../core/muiTheme";
import { useAdversaries } from "../../adversaries/useAdversaries";
import { AdversaryImage } from "../../adversaries/Adversary/AdversaryImage";
import { useAuth } from "../hooks/useAuth";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CloudOffIcon from "@mui/icons-material/CloudOff";

interface Props {
  onEdit: () => void,
  onDelete: () => void,
  onClick: () => void,
  onShouldStoreClick: () => void,
  encounter: EncounterState,
  selected: boolean
}

export function EncounterCatalogItem(props: Props) {
  const { onEdit, onDelete, onClick, onShouldStoreClick, encounter, selected } = props;

  return (
    <Paper
      sx={{
        display: 'flex',
        width: '100%',
        borderRadius: 1,
        // border: '2px solid #000',
        outline: selected ? `2px solid ${yellow[500]}` : undefined,
        my: 1.5,
        overflow: 'hidden',
        // bgcolor: "#fff",
      }}
      elevation={1}
    >
      <ButtonBase
        // selected={selected}
        onClick={onClick}
        sx={{
          flex: 1,
          textAlign: 'left',
          justifyContent: 'left',
          p: 0,
          fontWeight: 700,
          minWidth: 0,
          display: 'flex',
        }}
      >
        <Box sx={{ width: 100, minWidth: 100, display: { xs: 'none', sm: 'block' } }}>
          <EncounterImage encounter={encounter} />
        </Box>
        <Box sx={{ py: 1, px: 2, whiteSpace: "nowrap", overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {encounter.name}
        </Box>
      </ButtonBase>
      <ShouldStoreOnServerListItemButton current={encounter.shouldStoreOnServer} onClick={onShouldStoreClick} />
      <EncounterCatalogItemButton icon={<EditIcon />} onClick={onEdit} tooltip="Edit" />
      <EncounterCatalogItemButton icon={<DeleteIcon />} onClick={onDelete} tooltip="Delete" />
    </Paper>
  );
}

function ShouldStoreOnServerListItemButton(props: { current: boolean | undefined, onClick: () => void }) {
  const { isLoggedIn } = useAuth();
  if (!isLoggedIn) {
    return null;
  }

  return (
    <EncounterCatalogItemButton
      onClick={props.onClick}
      tooltip={props.current ? "Synced with account" : "Not synced with account"}
      icon={props.current ? <CloudDoneIcon /> : <CloudOffIcon />}
    />
  );
}

function EncounterCatalogItemButton(props: { onClick: () => void, tooltip: string, icon: React.ReactNode }) {
  return (
    <Tooltip
      title={props.tooltip}
      PopperProps={{
        modifiers: [{ name: "offset", options: { offset: [0, -20] } }],
      }}
    >
      <ButtonBase onClick={props.onClick} sx={{ flex: 0, p: 1 }}>
        {props.icon}
      </ButtonBase>
    </Tooltip>
  );
}

function EncounterImage(props: { encounter: EncounterState }) {
  const { findAdversary } = useAdversaries();
  const adversary = findAdversary(props.encounter.deck[0]);
  return (
    <AdversaryImage adversary={adversary} />
  );
}
