import { PropsWithChildren } from "react";
import { SxProps } from "@mui/system/styleFunctionSx";
import { ButtonBase } from "@mui/material";

interface Props {
  onClick: () => void,
  sx?: SxProps,
}

export function TransparentAddButton(props: PropsWithChildren<Props>) {
  return (
    <ButtonBase
      onClick={props.onClick}
      sx={{
        background: 'rgba(0, 0, 0, 0.1)',
        borderRadius: 2,
        flexGrow: 1,
        height: '100%',
        textTransform: 'uppercase',
        fontWeight: 700,
        color: '#c3c1ce',
        ...props.sx,
      }}
    >
        {props.children}
    </ButtonBase>
  );
}